const docHeight = () => {
  let root = document.documentElement
  let timeout = false
  let delay = 250
  function setDocHeight() {
    const headerButton = document.querySelector('.js-header-button')
    if (headerButton) {
      root.style.setProperty(
        '--hero',
        document.documentElement.clientHeight - headerButton.clientHeight + 'px'
      )
    } else {
      root.style.setProperty(
        '--hero',
        document.documentElement.clientHeight + 'px'
      )
    }
  }

  window.addEventListener('load', setDocHeight, false)
  window.addEventListener('resize', () => {
    clearTimeout(timeout)
    timeout = setTimeout(setDocHeight, delay)
  })
  window.addEventListener('orientationchange', setDocHeight, false)
}
docHeight()
